import { Box, Container, Grid, Typography } from '@mui/material';
import Lottie from 'react-lottie';
import mail from '../../lotties/mail.json';
import gem from '../../lotties/gem.json';
import circle_arrow from '../../lotties/circle_arrow.json';
import quotes from '../../assets/quotes.svg'
import webflow from '../../assets/webflow.svg'
import background from '../../assets/hero.png'
import underline from '../../assets/underline.svg'
import oleary_pic from '../../assets/oleary_pic.jpeg'
import scribble7 from '../../assets/scribble7.svg'
import scribble11 from '../../assets/scribble11.svg'

const SellingD = () => {
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Box component="img" src={scribble7} sx={{
        position: 'absolute',
        zIndex: 2
      }}/>
    </Box>
    <Box id="scope" sx={{
      backgroundImage: `url(${background}),linear-gradient(#fff,#fff)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16
    }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '40px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '50px', md: '60px' },
              mb: 12,
              wordBreak: 'break-word'
            }}
          >
            Apps, websites, infrastructure & more.
          </Typography>
        </Box>
        <Box sx={{
          p: 6,
          border: '6px solid #fbe8de',
          position: 'relative'
        }}>
          <Box component="img" src={scribble11} sx={{
            width: '100px',
            position: 'absolute',
            top: '-4%',
            bottom: 'auto',
            left: '-5%',
            right: 'auto'
          }}/>
          <Box sx={{
            gridColumnGap: '30px',
            gridRowGap: '0px',
            gridTemplateRows: 'auto',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr 1fr'
            },
            display: 'grid'
          }}>
            <Box sx={{
              gridArea: 'span 1/span 1/span 1/span 1'
            }}>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>React</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>SAAS</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Express / NodeJS</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>CMS</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Landing Page</Typography>
              </Box>
            </Box>
            <Box sx={{
              gridArea: 'span 1/span 1/span 1/span 1'
            }}>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Graphic Design</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Serverless / Cloud Functions</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Native Mobile</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Integration</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>MongoDB</Typography>
              </Box>
            </Box>
            <Box sx={{
              gridArea: 'span 1/span 1/span 1/span 1'
            }}>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Docker / K8s</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Authorization / Authentication</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Shopify / Ecommerce</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>SQL</Typography>
              </Box>
              <Box sx={{
                borderBottom: '6px solid #fbe8de',
                marginBottom: '30px',
                paddingBottom: '16px'
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontSize: '17px',
                  fontWeight: 700,
                }}>Webflow</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
    </>
  )
}

export default SellingD
