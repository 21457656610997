import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Grifter from './fonts/Grifter.ttf';
import GrifterOTF from './fonts/Grifter.otf';
import GrifterWoff from './fonts/Grifter.woff';
import GrifterWoff2 from './fonts/Grifter.woff2';
import PlusJakartaSans from './fonts/PlusJakartaSans.ttf';
import Inconsolata from './fonts/Inconsolata.ttf';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      custom: 760,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Grifter, sans-serif',
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: Grifter;
          font-style: normal;
          font-weight: normal;
          src: local(Grifter), url(${Grifter}) format('truetype'), url(${GrifterOTF}) format('otf'), url(${GrifterWoff}) format('woff'), url(${GrifterWoff2}) format('woff2');
        }
        @font-face {
          font-family: PlusJakartaSans;
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local(PlusJakartaSans), url(${PlusJakartaSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: Inconsolata;
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local(Inconsolata), url(${Inconsolata}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;