import { Box, Container, Grid, Typography } from '@mui/material';
import Lottie from 'react-lottie';
import mail from '../../lotties/mail.json';
import gem from '../../lotties/gem.json';
import circle_arrow from '../../lotties/circle_arrow.json';
import quotes from '../../assets/quotes.svg'
import webflow from '../../assets/webflow.svg'
import background from '../../assets/hero.png'
import scribble3 from '../../assets/scribble3.svg'
import scribble4 from '../../assets/scribble4.svg'

const SellingA = () => {
  return (
    <Box sx={{
      backgroundImage: `url(${background}),linear-gradient(#fff,#fff)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      overflow: 'hidden'
    }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '40px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '50px', md: '60px' },
              mb: 2,
              maxWidth: '760px',
              wordBreak: 'break-word'
            }}
          >
            We didn't reinvent the wheel, just web development
          </Typography>
          <Typography 
            variant="h4" 
            gutterBottom
            sx={{
              margin: '0 auto',
              fontFamily: 'PlusJakartaSans',
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '36px',
              mt: 3, 
              mb: 2,
              maxWidth: '700px'
            }}
          >
            Engineering as you know it is out the door. Engineering as you want it just arrived.
          </Typography>
        </Box>
        <Grid container spacing={2} mt={7}>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto'
            }}
            >
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: mail,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '26px',
                mt: 2, 
                mb: 2,
              }}
            >
              Subscribe to a plan & request as many engineering tasks as you'd like.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
              p: 1
            }}>
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: gem,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '26px',
                mt: 2, 
                mb: 2,
              }}
            >
            Receive your deliverables within a few business days on average, Monday to Friday. We'll revise the deliverable until you're 100% satisfied.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
              p: 1
            }}>
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: circle_arrow,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '26px',
                mt: 2, 
                mb: 2,
              }}
            >
            We'll revise the deliverable until you're 100% satisfied.
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{
          mt: 8,
          backgroundImage: `url(${background})`,
          backgroundPosition: '0 0',
          backgroundSize: 'auto',
          backgroundColor: '#fbe8de',
          p: 6,
          textAlign: 'center',
          position: 'relative'
        }}>
          <Box component="img" src={scribble3} sx={{
            position: 'absolute',
            top: '-4%',
            bottom: 'auto',
            left: 'auto',
            right: '-4%',
            width: {
              xs: '150px',
              md: 'inherit'
            }
          }}/>
          <Box component="img" src={scribble4} sx={{
            position: 'absolute',
            top: 'auto',
            bottom: '10%',
            left: '-5%',
            right: 'auto',
            width: {
              xs: '130px',
              md: 'inherit'
            }
          }}/>
          <Box component="img" src={quotes} />
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: '33px',
              fontWeight: '200',
              lineHeight: '40px',
              mt: 3,
              mb: 2,
              maxWidth: '600px'
            }}
          >
            Devjoi shows that they know the art of subtlety.
          </Typography>
          <Box 
            component="img" 
            src={webflow} 
            sx={{
              mt: 2,
              width: '110px'
            }}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default SellingA