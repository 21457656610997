import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

import { HashLink } from 'react-router-hash-link';

import logo from './assets/devjoi_logo.svg'

const pages = [{
  label: 'Benefits',
  anchor: 'benefits'
}, {
  label: 'Scope of work',
  anchor: 'scope'
}, {
  label: 'Pricing',
  anchor: 'pricing'
}, {
  label: 'FAQs',
  anchor: 'faqs'
}];


function TopNav() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{pt: 3}}>

          <Box sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' } }}
          />
          <Box sx={{
            flexGrow: 1,
            flexBasis: 0,
            display: { xs: 'flex', md: 'none' }
          }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                zIndex: 2
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ label, anchor }, i) => (
                <MenuItem key={i} onClick={handleCloseNavMenu}>
                  <Typography
                    component={HashLink}
                    to={`/#${anchor}`}
                    smooth
                    sx={{
                      fontFamily: 'PlusJakartaSans',
                      color: '#000',
                      textDecoration: 'none'
                    }}
                    textAlign="center"
                    >{label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            src={logo}
            sx={{
              width: '86px'
            }}
          />
          <Box sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' }
          }}>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopNav;
