import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Box, 
  Typography
} from '@mui/material'

import background from '../assets/hero.png'

const accordion_data = [
  {
    title: `Why wouldn't I just hire a full-time software engineer?`,
    details: `Good question! For starters, the annual cost of a full-time senior-level engineer now exceeds $100,000, plus benefits (and good luck finding one available). Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize.\n\nWith the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your engineer when you have work available for them.`
  },
  {
    title: 'Is there a limit to how many requests I can have?',
    details: `Once subscribed, you're able to add as many engineering requests to your queue as you'd like, and they will be delivered one by one.`
  },
  {
    title: 'How fast will I receive my requests?',
    details: 'On average, most requests are completed in just two days or less. However, more complex requests can take longer.'
  },
  {
    title: 'Who are the engineers?',
    details: `You might be surprised to hear this, but Devjoi is actually an agency of one. This means you'll work directly with me, founder of Devjoi.`
  },
  {
    title: 'How does the pause feature work?',
    details: `We understand you may not have enough engineering work to fill up entire month. Perhaps you only have one or two engineering requests at the moment. That's where pausing your subscription comes in handy.\n\nBilling cycles are based on 31 day period. Let's say you sign up and use the service for 21 days, and then decide to pause your subscription. This means that the billing cycle will be paused and you'll have 10 days of service remaining to be used anytime in the future.`
  },
  {
    title: 'How do I request engineering tasks?',
    details: `Devjoi offers a ton of flexibility in how you request engineering tasks using Trello. Some common ways clients request engineering tasks is directly via Trello, sharing Google docs or wireframes, or even recording a brief Loom video (for those who prefer not to write their briefs out). Basically, if it can be linked to or shared in Trello, it's fair game.`
  },
  {
    title: `What if I don't like the deliverable?`,
    details: `No worries! We'll continue to revise the deliverable until you're 100% satisfied.`
  },
  {
    title: 'What if I only have a single request?',
    details: `That's fine. You can pause your subscription when finished and return  when you have additional engineering needs. There's no need to let the remainder of your subscription go to waste.`
  },
  {
    title: `Are there any refunds if I don't like the service?`,
    details: 'Due to the high quality nature of the work, there will be no refunds issued.'
  },
]

export default function MyAccordion() {
  const [expanded, setExpanded] = useState(0);
  
  const handleChange = (index) => {
    console.log('event')
    console.log('index: ',index)
    setExpanded(index);
  };
  
  return (
    <Box sx={{
      maxWidth: '800px',
      margin: '0 auto',
      mt: 7
    }}>
      {
        accordion_data.map(({title,details}, i) => 
          <Accordion 
            key={i}
            expanded={expanded === i}
            onChange={(e) => handleChange(i)}
            variant="outlined" 
            sx={{
              borderColor: '#000'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ 
                py: 1,
              }}
            >
              <Typography sx={{
                fontFamily: 'PlusJakartaSans',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '30px'
              }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{
                fontFamily: 'PlusJakartaSans',
                fontSize: '17px',
                lineHeight: '26px',
                whiteSpace: 'pre-line'
              }}>
                {details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      }
    </Box>
  );
}