import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ParallaxProvider } from 'react-scroll-parallax';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";

import TermsConditions from './features/TermsConditions'
import PrivacyPolicy from './features/PrivacyPolicy'
import ScrollToTop from './features/ScrollToTop'
import App from './App';

import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={
            <ParallaxProvider>
              <App />
            </ParallaxProvider>
          }/>
          <Route path="terms-and-conditions" element={<TermsConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);