import { 
  Box, 
  Container, 
  Typography
} from '@mui/material';

import Accordion from './Accordion'
import background from '../assets/hero.png'
import scribble12 from '../assets/scribble12.svg'
import scribble15 from '../assets/scribble15.svg'
import scribble16 from '../assets/scribble16.svg'

const Pricing = () => {
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Box component="img" src={scribble12} sx={{
        position: 'absolute',
        zIndex: 2
      }}/>
    </Box>
    <Box id="faqs" sx={{
      backgroundImage: `url(${background}),linear-gradient(#fff,#fff)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      position: 'relative'
    }}>
      <Box component="img" src={scribble15} sx={{
        width: {
          xs: '140px',
          md: '180px',
          lg: '240px'
        },
        position: 'absolute',
        top: {
          xs: '-9%',
          md: '-12%',
          lg: '-16%'
        },
        bottom: 'auto',
        left: '-6%',
        right: 'auto'
      }}/>
      <Box component="img" src={scribble16} sx={{
        width: {
          xs: '90px',
          md: '120px',
          lg: '180px'
        },
        position: 'absolute',
        top: {
          xs: '-5%',
          md: '-4%',
          lg: '-5%'
        },
        bottom: 'auto',
        right: '0%',
        left: 'auto'
      }}/>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '50px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '60px', md: '60px' },
              mb: 2,
              maxWidth: {
                lg: '600px'
              }
            }}
          >
            FAQs
          </Typography>
        </Box>
        
        <Accordion />

      </Container>
    </Box>
    </>
  )
}

export default Pricing