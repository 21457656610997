import { createTheme } from '@mui/material/styles';
import PlusJakartaSans from './fonts/PlusJakartaSans.ttf';

// An alternate theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'PlusJakartaSans, sans-serif',
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'PlusJakartaSans';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('PlusJakartaSans'), url(${PlusJakartaSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export default theme;