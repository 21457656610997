import { Box, Button, Container, Link, List, ListItem, Grid, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import background from '../assets/hero.png'
import squiggle from '../assets/squiggle.svg'
import slashes from '../assets/slashes.svg'
import computer from '../assets/computer.svg'
import money from '../assets/money.svg'
import scribble12 from '../assets/scribble12.svg'
import scribble13 from '../assets/scribble13.svg'
import scribble14 from '../assets/scribble14.svg'

const Pricing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Box component="img" src={scribble12} sx={{
        position: 'absolute',
        zIndex: 2
      }}/>
    </Box>
    <Box id="pricing" sx={{
      backgroundImage: `url(${background}),linear-gradient(#fbe8de,#fbe8de)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      position: 'relative',
    }}>
      <Box component="img" src={scribble14} sx={{
        width: {
          xs: '90px',
          md: '110px',
          lg: '140px'
        },
        position: 'absolute',
        top: {
          xs: '-2%',
          md: '-6%',
          lg: '-6%'
        },
        bottom: 'auto',
        left: '0%',
        right: 'auto'
      }}/>
      <Box component="img" src={scribble13} sx={{
        width: {
          xs: '120px',
          md: '140px',
          lg: '180px'
        },
        position: 'absolute',
        top: {
          xs: '-2%',
          md: '-4%',
          lg: '-5%'
        },
        bottom: 'auto',
        right: '0%',
        left: 'auto'
      }}/>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '40px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '50px', md: '60px' },
              mb: 2,
              maxWidth: {
                lg: '600px'
              },
              wordBreak: 'break-word'
            }}
          >
            Membership levels
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              margin: '0 auto',
              fontFamily: 'PlusJakartaSans',
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '36px',
              mt: 3,
              mb: 2,
              maxWidth: {
                lg: '700px'
              }
            }}
          >
            Choose a plan that's right for you.
          </Typography>
          <Box
            sx={{
              mt: 6,
              maxWidth: '100%'
            }}
            component='img'
            src={squiggle}
          />
        </Box>
        <Grid container spacing={isMobile ? 2 : 0.25} mt={5}>
          <Grid item xs={12} md={4}>
            <Box sx={{ height: '100%'}}>
              <Box sx={{
                backgroundImage: `url(${background})`,
                backgroundPosition: '0 0',
                backgroundSize: 'auto',
                backgroundColor: '#fff',
                px: 4,
                pt: 8,
                pb: 3,
                position: 'relative'
              }}>
                <Box sx={{
                  color: '#fff',
                  letterSpacing: '1px',
                  backgroundColor: '#ea552b',
                  borderRadius: '5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '6px 9px',
                  fontSize: '10px',
                  fontWeight: 700,
                  lineHeight: '14px',
                  fontFamily: 'PlusJakartaSans',
                  display: 'flex',
                  position: 'absolute',
                  top: '-3%',
                  bottom: 'auto',
                  left: '9%',
                  right: 'auto',
                  transform: 'rotate(-9deg)'
                }}>MOST&nbsp;POPULAR</Box>
                <Box
                  component="img"
                  src={slashes}
                  sx={{
                    width: '20px',
                    position: 'absolute',
                    top: '-10%',
                    bottom: 'auto',
                    left: '22%',
                    right: 'auto'
                  }}
                />
                <Typography sx={{
                  fontSize: '24px',
                  fontWeight: 200,
                  lineHeight: '30px',
                  mb: 1
                }}>Standard
                </Typography>
                <Typography sx={{
                  fontSize: '15px',
                  lineHeight: '22px',
                  fontFamily: 'PlusJakartaSans',
                }}>One request at a time. Pause or cancel anytime.
                </Typography>
                <Typography sx={{
                  fontSize: '30px',
                  fontWeight: 200,
                  lineHeight: '42px',
                  mt: 11
                }}>$1,995/m
                </Typography>
                <Typography sx={{
                  fontSize: '12px',
                  opacity: 0.5,
                  fontFamily: 'PlusJakartaSans'
                }}>Pause or cancel anytime
                </Typography>
                <Button
                  component={Link}
                  href="https://buy.stripe.com/00g02c4E90Au86s000"
                  fullWidth
                  variant="contained"
                  sx={{
                    px: 7,
                    py: 2.5,
                    mt: 3,
                    fontSize: '17px',
                    fontWeight: 700
                  }}
                >Get started
                </Button>
                <Box sx={{
                  textAlign: 'center',
                  mt: 3
                }}>
                <Link
                  href="https://calendly.com/devjoi/devjoi-intro-1"
                  sx={{
                    fontFamily: 'PlusJakartaSans',
                    border: '1px #000',
                    borderBottomStyle: 'dotted',
                    fontWeight: 700,
                    fontSize: '14px',
                    textDecoration: 'none'
                  }}
                >Book a call</Link>
                </Box>
              </Box>
              <Box sx={{
                mt: 0.25,
                backgroundImage: `url(${background})`,
                backgroundPosition: '0 0',
                backgroundSize: 'auto',
                backgroundColor: '#fff',
                px: 4,
                pt: 4,
                pb: 9
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: 2
                }}>What's included:
                </Typography>
                <List
                  disablePadding
                  dense
                  sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                    [`& li.MuiListItem-root::marker`]: {
                      fontSize: '14px',
                      color: 'black'
                    }
                  }}
                >
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    One request at a time
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Average 48 hour delivery
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Unlimited brands
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Unlimited users
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Easy credit-card payments
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Pause or cancel anytime
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ height: '100%'}}>
              <Box sx={{
                backgroundImage: `url(${background})`,
                backgroundPosition: '0 0',
                backgroundSize: 'auto',
                backgroundColor: '#fff',
                px: 4,
                pt: 8,
                pb: 3,
              }}>
                <Typography sx={{
                  fontSize: '24px',
                  fontWeight: 200,
                  lineHeight: '30px',
                  mb: 1
                }}>Pro
                </Typography>
                <Typography sx={{
                  fontSize: '15px',
                  lineHeight: '22px',
                  fontFamily: 'PlusJakartaSans',
                }}>Double the requests. Pause or cancel anytime.
                </Typography>
                <Typography sx={{
                  fontSize: '30px',
                  fontWeight: 200,
                  lineHeight: '42px',
                  mt: 11
                }}>$3,195/m
                </Typography>
                <Typography sx={{
                  fontSize: '12px',
                  opacity: 0.5,
                  fontFamily: 'PlusJakartaSans'
                }}>Pause or cancel anytime
                </Typography>
                <Button
                  component={Link}
                  href="https://buy.stripe.com/8wMbKU0nT5UO5Yk8wx"
                  fullWidth
                  variant="contained"
                  sx={{
                    px: 7,
                    py: 2.5,
                    mt: 3,
                    fontSize: '17px',
                    fontWeight: 700
                  }}
                >Get started
                </Button>
                <Box sx={{
                  textAlign: 'center',
                  mt: 3
                }}>
                <Link
                  href="https://calendly.com/devjoi/devjoi-intro-1"
                  sx={{
                    fontFamily: 'PlusJakartaSans',
                    border: '1px #000',
                    borderBottomStyle: 'dotted',
                    fontWeight: 700,
                    fontSize: '14px',
                    textDecoration: 'none'
                  }}
                >Book a call</Link>
                </Box>
              </Box>
              <Box sx={{
                mt: 0.25,
                backgroundImage: `url(${background})`,
                backgroundPosition: '0 0',
                backgroundSize: 'auto',
                backgroundColor: '#fff',
                px: 4,
                pt: 4,
                pb: 9
              }}>
                <Typography sx={{
                  fontFamily: 'PlusJakartaSans',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: 2
                }}>What's included:
                </Typography>
                <List
                  disablePadding
                  dense
                  sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                    [`& li.MuiListItem-root::marker`]: {
                      fontSize: '14px',
                      color: 'black'
                    }
                  }}
                >
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    <strong>Two requests at a time</strong>
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Average 48 hour delivery
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Unlimited brands
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Unlimited users
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Easy credit-card payments
                  </ListItem>
                  <ListItem
                    disableGutters
                  sx={{
                    display: 'list-item',
                    fontFamily: 'PlusJakartaSans',
                    fontSize: '14px',
                    height: '20px',
                    lineHeight: '20px',
                    mb: 1,
                  }}>
                    Pause or cancel anytime
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
          <Box sx={{
            backgroundImage: `url(${background})`,
            backgroundPosition: '0 0',
            backgroundSize: 'auto',
            backgroundColor: '#fff',
            px: 4,
            pt: 8,
            pb: 4,
            height: '100%'
          }}>
            <Box sx={{
              pb: 4,
              borderBottom: '1px solid #000'
            }}>
              <Box
                component="img"
                src={computer}
                sx={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '100%'
                }}
              />
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  mt: 3
                }}
              >
              Book a call
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  lineHeight: '22px',
                  fontFamily: 'PlusJakartaSans',
                  mt: 1.5
                }}
              >
              Learn more about how DevJoi works and how it can help you.
              </Typography>
              <Link
                href="https://calendly.com/devjoi/devjoi-intro-1"
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  border: '1px #000',
                  borderBottomStyle: 'dotted',
                  fontWeight: 700,
                  fontSize: '14px',
                  textDecoration: 'none',
                  display: 'inline-block',
                  mt: 2
                }}
              >Book a call</Link>
            </Box>
            <Box sx={{
              pt: 4
            }}>
              <Box
                component="img"
                src={money}
                sx={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '100%'
                }}
              />
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  mt: 3
                }}
              >
              Refer a friend & earn
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  lineHeight: '22px',
                  fontFamily: 'PlusJakartaSans',
                  mt: 1.5
                }}
              >
              Earn 5% monthly recurring commissions for each referral.
              </Typography>
              <Tooltip sx={{fontFamily: 'PlusJakartaSans'}}
              title="Coming in April 2024 via Rewardful.">
                <Link
                  component="button"
                  sx={{
                    fontFamily: 'PlusJakartaSans',
                    border: '1px #000',
                    borderBottomStyle: 'dotted',
                    fontWeight: 700,
                    fontSize: '14px',
                    textDecoration: 'none',
                    display: 'inline-block',
                    mt: 2
                  }}
                >Join now</Link>
              </Tooltip>
            </Box>
          </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  )
}

export default Pricing
