import { Box, Container, Grid, Typography } from '@mui/material';
import quotes from '../../assets/quotes.svg'
import background from '../../assets/hero.png'
import underline from '../../assets/underline.svg'
import oleary_pic from '../../assets/oleary_pic.jpeg'
import scribble5 from '../../assets/scribble5.svg'
import scribble6 from '../../assets/scribble6.svg'
import scribble7 from '../../assets/scribble7.svg'


const SellingB = () => {
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Box component="img" src={scribble7} sx={{
        position: 'absolute',
        zIndex: 2
      }}/>
    </Box>
    <Box sx={{
      backgroundImage: `url(${background}),linear-gradient(#e1eaf4,#e1eaf4)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      position: 'relative'
    }}>
      <Box component="img" src={scribble5} sx={{
        width: {
          xs: '100px',
          md: '130px',
          lg: '180px'
        },
        position: 'absolute',
        top: {
          xs: '-7%',
          md: '-10%',
          lg: '-15%'
        },
        bottom: 'auto',
        left: '0%',
        right: 'auto'
      }}/>
      <Box component="img" src={scribble6} sx={{
        width: {
          xs: '140px',
          md: '170px',
          lg: '230px'
        },
        position: 'absolute',
        top: {
          xs: '-8%',
          md: '-11%',
          lg: '-20%'
        },
        bottom: 'auto',
        right: '0%',
        left: 'auto'
      }}/>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '40px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '50px', md: '60px' },
              mb: 2,
              maxWidth: '600px',
              wordBreak: 'break-word'
            }}
          >
            <Box component="span">It's "you'll </Box>
            <Box 
              component="span"
              sx={{
                backgroundImage: `url(${underline})`,
                backgroundPosition: '50% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
              }}
            >never
            </Box>
            <Box component="span"> go back" better</Box>
          </Typography>
          <Typography 
            variant="h4" 
            gutterBottom
            sx={{
              margin: '0 auto',
              fontFamily: 'PlusJakartaSans',
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '36px',
              mt: 3, 
              mb: 2,
              maxWidth: '700px'
            }}
          >
            Devjoi replaces unreliable freelancers and expensive agencies for one flat monthly fee, with engineering tasks delivered so fast that it will blow your mind.
          </Typography>
        </Box>
        <Grid container spacing={8} mt={5}>
          <Grid item xs={12} md={6}>
            <Box sx={{
              backgroundImage: `url(${background})`,
              backgroundPosition: '0 0',
              backgroundSize: 'auto',
              backgroundColor: '#fff',
              px: 5,
              py: 9
            }}>
              <Box component="img" src={quotes} />
              <Typography 
                variant="h1" 
                gutterBottom
                sx={{
                  fontSize: '33px',
                  fontWeight: '200',
                  lineHeight: '40px',
                  mt: 2,
                  mb: 2,
                }}
              >Engineering is everything, and these guys have nailed it.
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <Box sx={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#000',
                  backgroundImage: `url(${oleary_pic})`,
                  backgroundPosition: '90%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: '50%',
                  marginRight: '12px'
                }}/>
                <Box sx={{
                  fontFamily: 'Plusjakartasans',
                  fontSize: '17px',
                  fontWeight: 400,
                  lineHeight: '24px'
                }}>
                  <span>Kevin O'Leary, </span>
                  <span>🦈 <strong>Shark Tank</strong></span>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/*<Box sx={{}}>
              <Typography 
                variant="h3" 
                gutterBottom
                sx={{
                  fontWeight: '200',
                  lineHeight: '30px',
                  fontSize: '20px',
                  mb: 1,
                }}
              >Totally async
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom
                sx={{
                  fontFamily: 'Plusjakartasans',
                  maxWidth: '490px',
                  fontSize: '17px',
                  lineHeight: '24px',
                }}
              >Don't like meetings? We don't either; so much so that we've outlawed them completely.
              </Typography>
            </Box>*/}
            <Box sx={{
              mt: 0
            }}>
              <Typography 
                variant="h3" 
                gutterBottom
                sx={{
                  fontWeight: '200',
                  lineHeight: '30px',
                  fontSize: '20px',
                  mb: 1,
                }}
              >Manage with Trello
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom
                sx={{
                  fontFamily: 'Plusjakartasans',
                  maxWidth: '490px',
                  fontSize: '17px',
                  lineHeight: '24px',
                }}
              >Manage your engineering board using Trello. View active, queued and completed tasks with ease.
              </Typography>
            </Box>
            <Box sx={{
              mt: 5
            }}>
              <Typography 
                variant="h3" 
                gutterBottom
                sx={{
                  fontWeight: '200',
                  lineHeight: '30px',
                  fontSize: '20px',
                  mb: 1,
                }}
              >Invite unlimited team members
              </Typography>
              <Typography 
                variant="body1" 
                gutterBottom
                sx={{
                  fontFamily: 'Plusjakartasans',
                  maxWidth: '490px',
                  fontSize: '17px',
                  lineHeight: '24px',
                }}
              >Invite your entire team, so anyone can submit requests and track their progress.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  )
}

export default SellingB