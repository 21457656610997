import { 
  Box, 
  Button, 
  Container, 
  Link, 
  List, 
  ListItem, 
  Grid, 
  Typography, 
  useMediaQuery, 
  useTheme 
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Accordion from './Accordion'
import background from '../assets/hero.png'
import squiggle from '../assets/squiggle.svg'
import slashes from '../assets/slashes.svg'
import computer from '../assets/computer.svg'
import money from '../assets/money.svg'
import georgia from '../assets/georgia.svg'
import logo from '../assets/devjoi_logo_white.svg'

import client_1 from '../assets/client_1.svg'
import client_2 from '../assets/client_2.svg'
import client_3 from '../assets/client_3.svg'
import client_4 from '../assets/client_4.svg'
import client_6 from '../assets/client_6.svg'
import client_7 from '../assets/client_7.svg'
import scribble17 from '../assets/scribble17.svg'

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{
      backgroundImage: `url(${background}),linear-gradient(#000,#000)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      position: 'relative'
    }}>
      <Box component="img" src={scribble17} sx={{
        width: {
          xs: '90px',
          md: '120px',
          lg: '180px'
        },
        position: 'absolute',
        top: 'auto',
        bottom: '0',
        right: 'auto',
        left: '0'
      }}/>
      <Container maxWidth="lg">
        <Box sx={{ 
          maxWidth: '700px',
          margin: '0 auto'
        }}>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              textAlign: 'center',
              fontSize: { xs: '50px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '60px', md: '60px' },
              color: '#fff',
              mb: 2,
            }}
          >
            See if Devjoi is right for you. (It totally is.)
          </Typography>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              textAlign: 'center',
              fontSize: '24px',
              fontFamily: 'PlusJakartaSans',
              lineHeight: '36px',
              color: '#fff',
              mb: 2,
            }}
          >
            Get a guided tour through Devjoi, and find out how you and your team can change the way you source engineering, forever.
          </Typography>
          <Button 
            component={HashLink}
            smooth
            to="/#pricing"
            variant="outlined" 
            fullWidth
            sx={{ 
              px: 7,
              py: 5,
              mt: 4,
              fontSize: '28px',
              fontWeight: 700,
              color: '#fff',
              borderColor: '#fff',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#000',
              }
            }}
          >
          Get started
          </Button>
        </Box>
        
        <Box sx={{
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '60px',
          paddingTop: '40px',
          paddingBottom: '40px',
          display: 'flex',
          mb: '60px',
          textAlign: 'center',
        }}>
          <Box 
            sx={{ p: 3 }}
            component="img"
            src={client_1}
          />
          <Box 
            sx={{ p: 3 }}
            component="img"
            src={client_2}
          />
          <Box
            sx={{ p: 3 }}
            component="img"
            src={client_3}
          />
          <Box 
            sx={{ p: 3 }}
            component="img"
            src={client_4}
          />
          <Box 
            sx={{ p: 3 }}
            component="img"
            src={client_6}
          />
          <Box 
            sx={{ p: 3 }}
            component="img"
            src={client_7}
          />
        </Box>
        
        <Box sx={{
          display: {
            xs: 'block',
            md: 'flex'
          },
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Box 
              component="img" 
              sx={{
                width: '90px',
                mr: 3
              }}
              src={logo}
            />
            <Box 
              sx={{
                maxWidth: '40px',
                mr: 1
              }}
              component="img"
              src={georgia}
            />
            <Typography 
              sx={{ 
                color: 'white',
                maxWidth: '240px',
                fontSize: '14px',
                lineHeight: '19px',
                fontFamily: 'Inconsolata,monospace'
            }}>Devjoi is headquartered in Atlanta, GA.</Typography>
          </Box>
          <Box sx={{
            textAlign: 'left',
            justifyContent: {
              xs: 'flex-start',
              md: 'space-between'
            },
            alignItems: 'flex-start',
            display: 'flex',
            mt: {
              xs: 4,
              md: 0
            }
          }}>
            <Box sx={{
              mr: '60px'
            }}>
              <Link 
                component={HashLink}
                smooth 
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }} 
                to="/#pricing"
              >Pricing
              </Link>
              <Link 
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }}
                href="mailto:trey@devjoi.com?subject=Devjoi%20Inquiry">Contact
              </Link>
              <Link 
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }}
                href="https://billing.stripe.com/p/login/8wM14jciA8muaac144"
                >Client login
              </Link>
            </Box>
            <Box sx={{
            }}>
              <Link 
                component={HashLink}
                smooth 
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }}
                to="/#pricing"
              >Get started
              </Link>
              <Link 
                component={RouterLink}
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }}
                to="terms-and-conditions"
              >Terms & conditions
              </Link>
              <Link 
                component={RouterLink}
                sx={{
                  fontFamily: 'PlusJakartaSans',
                  color: '#fff',
                  marginBottom: '16px',
                  fontSize: '15px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  transition: 'all .2s',
                  display: 'block'
                }}
                to="privacy-policy"
                >Privacy policy
              </Link>
            </Box>
          </Box>
        </Box>

      </Container>
    </Box>
  )
}

export default Footer