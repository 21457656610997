import React, { useState, useEffect, useRef } from 'react'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { Parallax } from 'react-scroll-parallax'
import { HashLink } from 'react-router-hash-link';

import Marquee from './features/Marquee/Marquee'
import SellingA from './features/Selling/SellingA'
import SellingB from './features/Selling/SellingB'
import SellingC from './features/Selling/SellingC'
import SellingD from './features/Selling/SellingD'
import Pricing from './features/Pricing'
import Faq from './features/FAQ'
import Footer from './features/Footer'
import hero from './assets/hero.png'
import heart from './assets/heart.svg'
import carousel1 from './assets/carousel-1.jpeg'
import carousel2 from './assets/carousel-2.jpeg'
import carousel3 from './assets/carousel-3.jpeg'
import carousel4 from './assets/carousel-4.jpeg'
import carousel5 from './assets/carousel-5.jpeg'
import scribble1 from './assets/scribble1.svg'
import scribble2 from './assets/scribble2.svg'
import latest_projects from './assets/latest_projects.svg'

import TopNav from './TopNav'

const carousel_images = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5
]

function SectionHero() {
  const [targetElement, setTarget] = useState();
  const targetRef = useRef();
  useEffect(() => {
    setTarget(targetRef.current);
  }, [targetRef]);
  return (
    <Box sx={{ 
      backgroundImage: `url(${hero}),linear-gradient(#fbe8de,#fbe8de)`,
      overflow: 'hidden',
      position: 'relative'
    }}>
      <TopNav />
      <Box 
        component="img"
        src={scribble1}
        sx={{
          maxWidth: {
            xs: '100px',
            // custom: '120px',
            custom: '180px',
            md: '160px',
            lg: '200px'
          },
          position: 'absolute',
          top: {
            xs: '3%',
            custom: '10%',
            md: '15%'
          },
          bottom: 'auto',
          left: {
            xs: '-8%',
            custom: '0%',
          },
          right: 'auto'
        }}
      />
      <Box 
        component="img"
        src={scribble2}
        sx={{
          maxWidth: {
            xs: '100px',
            // custom: '120px',
            custom: '180px',
            md: '160px',
            lg: '220px'
          },
          position: 'absolute',
          top: {
            xs: '46%',
            custom: '14%',
            md: '10%'
          },
          bottom: 'auto',
          left: 'auto',
          right: {
            xs: '-9%',
            custom: '0%',
          },
        }}
      />
      <Container maxWidth="lg">
      <Box sx={{ textAlign: 'center', position: 'relative' }}>
        <Typography 
          variant="h1" 
          gutterBottom
          sx={{
            margin: '0 auto',
            textAlign: 'center',
            fontSize: { xs: '50px', md: '80px' },
            fontWeight: '200',
            lineHeight: { xs: '60px', md: '90px' },
            mt: { xs: 12, sm: 15 }, 
            mb: 2,
            maxWidth: {
              xs: '100%',
              sm: '540px',
              md: '800px'
            }
          }}
        >
          A web dev agency with a twist
        </Typography>
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            fontFamily: 'PlusJakartaSans',
            textAlign: 'center',
            fontSize: '24px',
            lineHeight: '36px',
            mt: 3, mb: 2
          }}
        >
          Engineering subscriptions to scale your business.
        </Typography>
        <Button 
          component={HashLink}
          smooth
          to="/#pricing"
          variant="contained" 
          sx={{ 
            px: 7,
            py: 2.5,
            mt: 3,
            fontSize: '17px',
            fontWeight: 700
          }}
        >
        See plans
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 4,
            fontFamily: 'PlusJakartaSans',
            fontSize: '13px'
          }}
        >
          <span>Engineering you'll</span>
          <img alt="heart" style={{marginLeft: '5px', marginRight: '2px'}} src={heart}/>
          <span>, guaranteed</span>
        </Box>
      </Box>
      </Container>
      <Container sx={{position: 'relative'}}>
        {targetElement && (
          <Box sx={{
            zIndex: '10',
            display: 'inline-block',
            position: 'absolute',
            top: '35px'
          }}>
            <Parallax
              rotateZ={[0, 180]}
              easing="easeInQuad"
              targetElement={targetElement}
            >
              <Box 
                component='img' 
                src={latest_projects} 
                sx={{
                  display: 'block',
                  width: '130px',
                }}
              />
            </Parallax>
          </Box>
        )}
      </Container>
      <Parallax
        translateX={['0%', '-10%']}
        easing="easeInQuad"
      >
        <Box sx={{
          my: 12,
          minWidth: '110%',
          justifyContent: 'space-between',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex'
        }}>
          {
            carousel_images.map((img, i) => 
              <Box 
                key={i}
                component="img"
                src={img} 
                sx={{
                  width: 'auto',
                  maxWidth: 'none',
                  minHeight: { xs: '300px', md: '400px' },
                  minWidth: { xs: '300px', md: '400px' },
                  boxShadow: 'none',
                  marginRight: '20px',
                  transition: 'all .4s',
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 30px 40px #a88f83'
                  }
                }}
              />
            )
          }
        </Box>
      </Parallax>
      <div ref={targetRef}></div>
    </Box>
  )
}

export default function App() {
  return (
    <>
    <SectionHero />
    <Marquee />
    <SellingA />
    <SellingB />
    <SellingC />
    <SellingD />
    <Pricing />
    <Faq />
    <Footer/>

    </>
  )
}