import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import Lottie from 'react-lottie';
import infinity from '../../lotties/infinity.json';
import lightning from '../../lotties/lightning.json';
import money from '../../lotties/money.json';
import paint from '../../lotties/paint.json';
import square from '../../lotties/square.json';
import fingerprint from '../../lotties/fingerprint.json';

import quotes from '../../assets/quotes.svg'
import webflow from '../../assets/webflow.svg'
import background from '../../assets/hero.png'
import scribble8 from '../../assets/scribble8.svg'
import scribble9 from '../../assets/scribble9.svg'
import scribble10 from '../../assets/scribble10.svg'

const SellingC = () => {
  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}>
      <Box component="img" src={scribble9} sx={{
        position: 'absolute',
        zIndex: 2
      }}/>
    </Box>
    <Box id="benefits" sx={{
      backgroundImage: `url(${background}),linear-gradient(#fff,#fff)`,
      backgroundPosition: '0 0,0 0',
      backgroundSize: 'auto,auto',
      py: 16,
      position: 'relative'
    }}>
      <Box component="img" src={scribble10} sx={{
        width: {
          xs: '150px',
          lg: '190px'
        },
        position: 'absolute',
        top: {
          xs: '-6%',
          md: '-10%',
          lg: '-13%'
        },
        bottom: 'auto',
        left: '0%',
        right: 'auto'
      }}/>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center' }}>
          <Box component="img" src={scribble8} sx={{
            mb: 3
          }}/>
          <Typography 
            variant="h1" 
            gutterBottom
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              fontSize: { xs: '40px', md: '50px' },
              fontWeight: '200',
              lineHeight: { xs: '50px', md: '60px' },
              mb: 2,
              maxWidth: '760px',
              wordBreak: 'break-word'
            }}
          >
            Membership benefits
          </Typography>
          <Typography 
            variant="h4" 
            gutterBottom
            sx={{
              margin: '0 auto',
              fontFamily: 'PlusJakartaSans',
              textAlign: 'center',
              fontSize: '24px',
              lineHeight: '36px',
              mt: 3, 
              mb: 2,
              maxWidth: '700px'
            }}
          >
            Perks so good you'll never need to go anywhere else for your engineering. Seriously.
          </Typography>
          <Button
            component={HashLink}
            smooth
            to="/#pricing"
            variant="contained" 
            sx={{ 
              px: 7,
              py: 2.5,
              mt: 3,
              fontSize: '17px',
              fontWeight: 700
            }}
          >
          See plans
          </Button>
        </Box>
        <Grid container spacing={2} mt={7}>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto'
            }}
            >
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: infinity,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Engineering board
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              Add as many engineering requests to your board as you'd like.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
              p: 1
            }}>
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lightning,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Lightning fast delivery
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              Get your deliverable one at a time in just a few days on average.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto',
              p: 1
            }}>
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: money,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Fixed monthly rate
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              No surprises here! Pay the same fixed price each month.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto'
            }}
            >
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: paint,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Top-notch quality
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              Insane engineering quality at your fingertips whenever you need it.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto'
            }}
            >
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: square,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Flexible and scalable
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              Scale up or down as needed, and pause or cancel at anytime.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              height: 100, 
              width: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mx: 'auto'
            }}
            >
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: fingerprint,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Typography 
              variant="h3" 
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 700,
                mt: 2, 
                mb: 2,
              }}
            >
              Unique and all yours
            </Typography>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                margin: '0 auto',
                fontFamily: 'PlusJakartaSans',
                textAlign: 'center',
                fontSize: '17px',
                lineHeight: '24px',
                maxWidth: '320px',
                mt: 2, 
                mb: 2,
              }}
            >
              Each of your deliverables is made especially for you and is 100% yours.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  )
}

export default SellingC