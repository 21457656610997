import Marquee from "react-fast-marquee";
import Box from '@mui/material/Box';

import client1 from '../../assets/client_1.svg'
import client2 from '../../assets/client_2.svg'
import client3 from '../../assets/client_3.svg'
import client4 from '../../assets/client_4.svg'
import client5 from '../../assets/client_5.svg'
import client6 from '../../assets/client_6.svg'
import client7 from '../../assets/client_7.svg'
import './Marquee.css';

const client_images = [
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7
]

function MyMarquee() {
  return (
    <Box sx={{
      background: '#000',
      py: 3
    }}>
      <Marquee
        autoFill={true}
      >
        {
          client_images.map((img, i) => 
              <Box 
                key={i}
                component="img"
                src={img}
                sx={{
                  p: 3,
                  ...(i === 4 && { height: '70px'})
                }}
              />
          )
        }
      </Marquee>
    </Box>
  )
}

export default MyMarquee