import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, ThemeProvider, Typography } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import theme from '../themeAlt';

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
    <Container>
      <Box sx={{
        py: 10,
        margin: '0 auto',
        maxWidth: '700px',
      }}>
        <Link 
          component={RouterLink} 
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4,
            fontFamily: 'PlusJakartaSans'
          }}
        >
           <KeyboardBackspaceIcon/> 
           <Box component="span" ml={1}>Back to homepage</Box>
        </Link>
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          This privacy notice for Devjoi ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you: Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
        </Typography>
        <Typography variant="h5" mt={2} gutterBottom>
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="h6" mt={2} gutterBottom>
          Personal information you disclose to us
        </Typography>
        <Typography variant="body1" gutterBottom>
        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

        Sensitive Information. We do not process sensitive information.

        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
        </Typography>
        <Typography variant="h6" mt={2} gutterBottom>
          Information automatically collected
        </Typography>
        <Typography variant="body1" gutterBottom>
        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.

        Like many businesses, we also collect information through cookies and similar technologies.
        </Typography>
        <Typography variant="h6" mt={2} gutterBottom>
          2. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography variant="body1" gutterBottom>
        We may need to share your personal information in the following situations:

        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

        Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.

        Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.
        </Typography>
        <Typography variant="h6" mt={2} gutterBottom>
          3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography variant="body1" gutterBottom>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
        </Typography>
        <Typography variant="h6" mt={2} gutterBottom>
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" gutterBottom>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).

        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </Typography>
      </Box>

    </Container>
    </ThemeProvider>
  )
}

export default PrivacyPolicy